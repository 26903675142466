import BrandTrack from '@bww/beke-ui/Brands/BrandTrack'
import Link from 'next/link'

import styles from './BrandsSection.module.scss'
import classNames from 'classnames'

export default function BrandsSection({ brands, children }) {
  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.logosWrapper}>
          <BrandTrack rows={5} brands={brands} />
        </div>

        <div className={styles.contentWrapper}>
          <div className={classNames(styles.contentContainer, 'container')}>
            <div className={styles.content}>
              <header>
                <h2 className="h1">
                  <strong>Premium brands</strong>
                  <br />
                  Premium experience
                </h2>
              </header>
              {children}
              <Link href="/brands" className="button button--small">
                Our partner brands
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
