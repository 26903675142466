import ProductCard from './items/ProductCard'
import InsightCard from './items/InsightCard'
import ProjectCard from './items/ProjectCard'

export default function InspirationItem(props) {
  if (props.type === 'product-feature') return <ProductCard {...props} />
  if (props.type === 'insight') return <InsightCard {...props} />
  if (props.type === 'project') return <ProjectCard {...props} />
  return null
}
