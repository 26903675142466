import styles from './CreditTag.module.scss'
import { motion } from 'framer-motion'
import Link from 'next/link'
import chevronIcon from '@bww/beke-ui/icons/chevron-right.svg'

const PROJECT_PATH = '/projects'

const MotionLink = motion(Link)

export default function CreditTag({
  title,
  href,
  projectSlug,
  position = ['bottom', 'right'],
}) {
  const positionStyles = {}
  const X_POSITION_SPACING = '20px'
  const Y_POSITION_SPACING = '10px'

  for (let pos of position) {
    if (pos === 'top' || pos === 'bottom')
      positionStyles[pos] = Y_POSITION_SPACING
    if (pos === 'left' || pos === 'right')
      positionStyles[pos] = X_POSITION_SPACING
  }

  const linkHref = projectSlug ? `${PROJECT_PATH}/${projectSlug}` : href || null

  if (!title || !linkHref) return null

  return (
    <MotionLink
      href={linkHref}
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.4, delay: 0.3 }}
      viewport={{
        margin: '-20px',
        once: true,
      }}
      className={styles.creditTag}
      style={positionStyles}
    >
      <div>{title}</div>
      <svg width="1em" height="1em" viewBox="0 0 256 256">
        <use href={`${chevronIcon.src}#icon`} />
      </svg>
    </MotionLink>
  )
}
