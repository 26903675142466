import BrandChip from './BrandChip'
import styles from './BrandTrack.module.scss'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef, useState, useEffect, useMemo } from 'react'

// TODO add priority/weighting

const MAX_BRANDS = 5
const SEED_MODIFIER = 7 //* adjust to modify logo order

export default function BrandTrack({ brands, rows = 3 }) {
  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(null)

  // TODO accommodate resizing browser
  useEffect(() => {
    if (!containerRef?.current?.offsetWidth) return
    setContainerWidth(containerRef?.current?.offsetWidth)
  }, [containerRef?.current?.offsetWidth])

  const isLarge = containerWidth && containerWidth >= 720

  const numBrandColumns = Math.max(
    3,
    containerWidth
      ? Math.round(containerWidth / (isLarge ? 260 : 160)) // allows 10px gap
      : MAX_BRANDS,
  )

  const shuffledRows = useMemo(() => {
    const shuffledBrands = [...brands]
    const reserveBrands = [...brands]
    shuffleArray(shuffledBrands, SEED_MODIFIER)
    shuffleArray(reserveBrands, SEED_MODIFIER + 5)
    return [...Array(rows)].map(() => {
      if (shuffledBrands.length === 0) return []

      if (shuffledBrands.length >= numBrandColumns)
        return shuffledBrands.splice(0, numBrandColumns)

      // handle running out
      const shortfall = numBrandColumns - shuffledBrands.length
      return [
        ...shuffledBrands.splice(0),
        ...reserveBrands.splice(0, shortfall),
      ]
    })
  }, [rows, brands, numBrandColumns])

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['-200px center', '1 50vh'],
  })

  const X_SHIFT = 25

  const trackX = useTransform(scrollYProgress, [0, 1], [0, -X_SHIFT])
  const trackXRev = useTransform(scrollYProgress, [0, 1], [-X_SHIFT, 0])

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      {containerWidth &&
        [...Array(rows)].map((_, i) => (
          <motion.div
            className={styles.track}
            style={{ x: i % 2 === 0 ? trackX : trackXRev }}
            key={i}
          >
            {shuffledRows[i].map(({ logo, name }) => (
              <BrandChip
                src={logo.src}
                bgFill={logo.bleedColor}
                name={name}
                key={name}
              />
            ))}
          </motion.div>
        ))}
    </div>
  )
}

function seededRandom(max = 1, min = 0, seed) {
  const newSeed = (seed * 9301 + 49297) % 233280
  const rnd = newSeed / 233280
  return min + rnd * (max - min)
}

function shuffleArray(array, seed) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(seededRandom(1, 0, seed * i) * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}
