import Link from 'next/link'

import styles from './InspirationSection.module.scss'

import InspirationItem from '../Inspiration/InspirationItem'

export default function InspirationSection({ items }) {
  if (!items || items.length === 0) return null
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <header className={styles.header}>
          <h2 className="h1">Find inspiration</h2>
        </header>
        <div className={styles.itemsContainer}>
          {items.map((item) => (
            <InspirationItem {...item} key={item.slug} />
          ))}
        </div>

        <div className={styles.moreTextContainer}>
          <p>
            Browse curated products and room sets from our brand partners.
            <br />
            Many of these products are on display in our showroom.
          </p>
          <Link
            href="/inspiration"
            className="button"
            style={{ marginTop: 30 }}
          >
            Bathroom Inspiration
          </Link>
        </div>
      </div>
    </section>
  )
}
