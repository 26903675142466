import { ShowcaseImageWrapper } from '../ShowcaseImage/ShowcaseImage'

import ProjectHero from './ProjectHero'
import ProductHero from './ProductHero'

// TODO options for type project and type product feature (+video)

export default function HeroImage({ hero }) {
  return (
    <section style={{ borderBottom: '2px solid var(--color-primary-darker)' }}>
      <ShowcaseImageWrapper minHeight="50vh" maxHeight="75vh">
        {hero.type === 'project' && <ProjectHero hero={hero} />}
        {hero.type === 'product-feature' && <ProductHero hero={hero} />}
      </ShowcaseImageWrapper>
    </section>
  )
}
