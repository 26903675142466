import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'
import styles from './ParallaxContent.module.scss'

export default function ParallaxContent({ parallaxEl, children, offset = 70 }) {
  const targetRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['0 15vh', '1 15vh'],
  })
  const yOffset = useTransform(scrollYProgress, [0, 1], [0, offset])

  return (
    <div className={styles.wrapper} ref={targetRef}>
      <div className={styles.innerWrapper} style={{ top: '-3vh' }}>
        <motion.div style={{ height: '100%', y: yOffset }}>
          {parallaxEl}
        </motion.div>
        {children && <div className={styles.content}>{children}</div>}
      </div>
    </div>
  )
}
