import { PortableText } from '@portabletext/react'
import Card from '../InspirationCard'
import styles from '../InspirationFeatures.module.scss'

export default function ProductCard({ brand, image, title, description }) {
  return (
    <Card
      image={image}
      content={
        <>
          <div className={styles.logoWrapper}>
            <img
              className={styles.logoImg}
              src={brand.logo.src}
              alt={`${brand.title} logo`}
              title={brand.title}
            />
          </div>

          {title && <h2 className={styles.title}>{title}</h2>}
        </>
      }
      expandedContent={
        description ? (
          <>
            {title && <h2 className="h5">{title}</h2>}
            {description && <PortableText value={description} />}
          </>
        ) : null
      }
    />
  )
}
