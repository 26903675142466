import styles from './YourDreamCTA.module.scss'
import { motion } from 'framer-motion'

const locations = [
  'home office',
  'utility room',
  'kitchen',
  'home',
  'bathroom',
  'bedroom',
  'lounge',
]

const itemVariants = {
  hidden: (level) => ({
    y: 30 * Math.sign(level) * -1,
    x: level === 0 ? 20 : 0,
    opacity: 0,
  }),
  visible: (level) => ({
    y: 0,
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 20,
      delay: level === 0 ? 1.5 : 2.3 + Math.abs(level) / 20,
    },
  }),
}

export default function YourDreamSlider({ animated = true }) {
  return (
    <motion.div
      className={styles.sliderContainer}
      initial={animated ? 'hidden' : 'visible'}
      whileInView="visible"
      viewport={{
        once: true,
      }}
    >
      <motion.div
        variants={{
          hidden: { x: -30, opacity: 0 },
          visible: { x: 0, opacity: 1 },
        }}
        transition={{ duration: 0.3, delay: 1 }}
      >
        Your dream
      </motion.div>
      <div>
        <ul>
          {locations.map((location, i) => {
            const layer = calcLayer(i, locations.length)
            return (
              <motion.li key={location} custom={layer} variants={itemVariants}>
                {location}
              </motion.li>
            )
          })}
        </ul>
      </div>
    </motion.div>
  )
}

function calcLayer(index, numItems) {
  const midPoint = Math.ceil(numItems / 2)
  const position = index + 1
  return position - midPoint
}
