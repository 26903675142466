import styles from './BrandChip.module.scss'
import Link from 'next/link'
import classNames from 'classnames'
import { ConditionalWrapper } from '../ConditionalWrapper'

export default function BrandChip({ src, name, bgFill, href, hasBrandPage }) {
  const style = {}
  if (bgFill) style.backgroundColor = bgFill

  const logoSrc = src?.src || src

  return (
    <ConditionalWrapper
      condition={typeof href === 'string'}
      wrapper={(children) => (
        <Link
          href={href}
          className={classNames({ [styles.supplierLink]: !hasBrandPage })}
        >
          {children}
        </Link>
      )}
    >
      <div className={styles.chip} style={style}>
        <img
          className={styles.logoImg}
          src={logoSrc}
          alt={`${name} logo`}
          title={name}
        />
      </div>
    </ConditionalWrapper>
  )
}
