import { createClient } from 'next-sanity'
import imageUrlBuilder from '@sanity/image-url'
import { sanityConfig } from './site-config'

const { projectId, dataset, apiVersion, useCdn } = sanityConfig

export const sanityClient = createClient({
  projectId,
  dataset,
  apiVersion,
  useCdn,
})

const builder = imageUrlBuilder(sanityClient)
export const imgUrl = (source) => builder.image(source)
//.auto('format') // removed for perf since nextjs reformats images
