import Image from 'next/image'
import ParallaxContent from '../ParallaxContent'
import styles from './ProductHero.module.scss'
import classnames from 'classnames'

import { imgUrl } from '@bww/beke-lib/sanity-client'

import infoIcon from '@bww/beke-ui/icons/info.svg'

// TODO options for type project and type product feature (+video)

export default function ProductHero({ hero }) {
  const { images, title, description, brand } = hero
  const heroImg = images[0]
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <ParallaxContent
        parallaxEl={
          <Image
            priority
            fill
            src={imgUrl(heroImg).fit('max').width(2560).url()}
            style={{ objectFit: 'cover' }}
            alt={heroImg.alt || title}
          />
        }
      >
        <div className={classnames('container', styles.infoRow)}>
          <div className={styles.logoWrapper}>
            <img
              className={styles.logoImg}
              src={brand.logo.src}
              alt={`${brand.title} logo`}
              title={brand.title}
            />
          </div>

          {title && (
            <div className={styles.titleTag}>
              <svg width="1em" height="1em" viewBox="0 0 256 256">
                <use href={`${infoIcon.src}#icon`} />
              </svg>
              <span className={styles.title}>{title}</span>
            </div>
          )}
        </div>
      </ParallaxContent>
    </div>
  )
}
