import Image from 'next/image'
import CreditTag from '../CreditTag/CreditTag'

export default function ShowcaseImage({ image, credit }) {
  const tagWrapperStyle = {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  }

  /* eslint-disable jsx-a11y/alt-text */
  return (
    <div style={tagWrapperStyle}>
      {credit && <CreditTag {...credit} />}
      <Image {...image} />
    </div>
  )
}

export function ShowcaseImageWrapper({
  aspectRatio = 1.8,
  // maxHeight,
  minHeight,
  children,
}) {
  const wrapperStyle = {
    display: 'flex',
    maxWidth: '100%',
    position: 'relative',
    aspectRatio,
  }

  // if (maxHeight !== undefined) wrapperStyle.maxHeight = maxHeight
  if (minHeight !== undefined) wrapperStyle.minHeight = minHeight

  return <div style={wrapperStyle}>{children}</div>
}
