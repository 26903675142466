import styles from './home-intro.module.scss'
import ShowcaseImage from '@bww/beke-ui/ShowcaseImage/ShowcaseImage'
import Link from 'next/link'

import classNames from 'classnames'

export default function HomeIntro() {
  return (
    <section className={styles.homeIntro}>
      <header className={styles.header}>
        <h1 className={classNames('h1', styles.heading)}>
          Bathroom designers and suppliers in Poole
        </h1>
        <Link
          href="/poole-showroom"
          className={classNames(styles.hookText, 'capText')}
        >
          <span>50 displays</span>
          <wbr />
          <span>4,000 square foot showroom</span>
        </Link>
      </header>
      <div className={styles.panel}>
        <div className={styles.panelContent}>
          <h2 className="h4">Make every morning a joy</h2>
          <p>
            Starting and finishing your day in a new bathroom you utterly love
            is one of life's great pleasures.
          </p>
          <p>
            Bathroom Elegance offer design and supply to private and trade
            customers in Southern England for projects of all sizes.
          </p>
          <p>
            Over the past two decades we've learned the many secrets of
            achieving exceptional results cost effectively.
          </p>
        </div>
      </div>

      <div className={styles.imageWrapper}>
        <ShowcaseImage
          image={{
            src: 'https://cdn.sanity.io/images/ly18yecr/production/3af5a6917b489ad99595c9c2cd80dd9ea19d3c4e-7364x6773.jpg?w=1325',
            fill: true,
            style: { objectFit: 'cover', objectPosition: 'right center' },
            alt: 'Wet room with turquoise accents',
          }}
          credit={{
            title: 'Wet room with turquoise accents',
            projectSlug: 'wet-room-with-turquoise-accents',
          }}
        />
      </div>
    </section>
  )
}
