import styles from './InspirationFeatures.module.scss'
import Image from 'next/image'
import { imgUrl } from '@bww/beke-lib/sanity-client'
import { useState } from 'react'
import { motion } from 'framer-motion'

export default function Card({ image, title, content, expandedContent }) {
  const [expanded, setExpanded] = useState(false)
  const handleClick = !expandedContent
    ? null
    : () => {
        setExpanded(!expanded)
      }

  return (
    <div className={styles.slideWrapper}>
      <motion.div
        className={styles.slide}
        tabIndex="-1"
        whileHover={!expanded ? 'hint' : 'open'}
        animate={expanded ? 'open' : 'closed'}
        onClick={handleClick}
      >
        <motion.div
          className={styles.imgWrapper}
          variants={{
            open: { x: '-15%' },
            closed: { x: 0 },
          }}
          transition="spring"
        >
          <Image
            src={imgUrl(image).fit('max').width(1360).url()} //? Make large/small size dependent
            sizes="(max-width: 680px) 100vw, 1360px"
            alt={image.alt || title}
            placeholder={image.lqip ? 'blur' : undefined}
            blurDataURL={image.lqip}
            style={{ objectFit: 'cover' }}
            className={styles.heroImg}
            fill
          />
        </motion.div>
        {content && <div className={styles.content}>{content}</div>}
        {expandedContent && (
          <motion.div
            className={styles.expandedInfo}
            variants={{
              open: { opacity: 1, x: 0 },
              closed: { opacity: 0, x: '100%' },
              hint: { opacity: 1, x: '96%' },
            }}
            transition="spring"
          >
            {expandedContent}
          </motion.div>
        )}
      </motion.div>
    </div>
  )
}
