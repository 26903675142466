import ParallaxContent from '../ParallaxContent'
import Image from 'next/image'
import CreditTag from '../CreditTag/CreditTag'

import { imgUrl } from '@bww/beke-lib/sanity-client'

// TODO video

export default function ProjectHero({ hero }) {
  const { heroImg, title, slug } = hero

  return (
    <ParallaxContent
      parallaxEl={
        <Image
          priority
          fill
          src={imgUrl(heroImg).fit('max').width(2560).url()}
          style={{ objectFit: 'cover' }}
          alt={heroImg.alt || title}
        />
      }
    >
      <CreditTag title={title} projectSlug={slug} />
    </ParallaxContent>
  )
}
