import { PortableText } from '@portabletext/react'
import Card from '../InspirationCard'

export default function InsightCard({ image, title, description }) {
  return (
    <Card
      image={image}
      content={<h2 className="h2">{title}</h2>}
      expandedContent={
        <>
          <h2 className="h5">{title}</h2>
          {description && <PortableText value={description} />}
        </>
      }
    />
  )
}
