import styles from './YourDreamCTA.module.scss'
import Link from 'next/link'
import YourDreamSlider from './YourDreamSlider'
import classNames from 'classnames'

export default function YourDream({
  animated = true,
  ctaText = `Let's talk about your project`,
}) {
  return (
    <section>
      <div className={classNames(styles.container, 'container')}>
        <div>
          <YourDreamSlider animated={animated} />
        </div>
        <div className={styles.ctaContainer}>
          <h3 className="h2">{ctaText}</h3>
          <Link href="/contact" className="button button--accent">
            Contact our team
          </Link>
        </div>
      </div>
    </section>
  )
}
