import styles from './Testimonials.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import { PortableText } from '@portabletext/react'

import classNames from 'classnames'

import { useState, useCallback } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { isKE } from '@bww/beke-lib/site-config'

const assetBaseUrl = 'https://cdn.sanity.io/images/ly18yecr/production/'
const asset = isKE
  ? '19a62d13cc324126830d1b7d42a2a3456f154123-4096x2730.jpg'
  : '4c48e5139fa82bbdc37f7ecbeb3eda220d06a0e9-4000x3000.jpg'
const bgSrc = assetBaseUrl + asset

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

export default function Testimonials({ testimonials }) {
  const [[page, direction], setPage] = useState([0, 0])
  const testimonialIndex = wrap(0, testimonials.length, page) //! page is 1...999, index wraps
  const testimonial = testimonials[testimonialIndex]

  const paginate = useCallback(
    (newDirection) => {
      setPage([page + newDirection, newDirection])
    },
    [setPage, page],
  )

  // TODO revisit
  const navigate = (newPage) => {
    setPage([newPage, newPage < page ? -1 : 1])
  }

  return (
    <section className={styles.section}>
      <Image src={bgSrc} fill quality={40} className={styles.bgImg} alt="" />
      <div className={styles.container}>
        <h3
          className="h4"
          style={{ textAlign: 'center', color: 'var(--color-alt-tint-light)' }}
        >
          Kind words from
          <br />
          our customers
        </h3>
        <div className={styles.contentContainer}>
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              className={styles.page}
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1)
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1)
                }
              }}
            >
              <Testimonial
                highlight={testimonial.pullQuote}
                excerpt={testimonial.excerpt.blocks}
                isCapped={testimonial.excerpt.isCapped}
                name={testimonial.clientName}
              />
            </motion.div>
          </AnimatePresence>
          <button
            className={styles.next}
            onClick={() => paginate(1)}
            aria-label="Next"
          >
            {'‣'}
          </button>
          <button
            className={styles.prev}
            onClick={() => paginate(-1)}
            aria-label="Previous"
          >
            {'‣'}
          </button>
        </div>
        <div className={styles.pipsContainer}>
          {testimonials.map((_, i) => (
            <button
              className={classNames(styles.pip, {
                [styles.pipActive]: i === testimonialIndex,
              })}
              onClick={() => navigate(i)}
              key={i}
              aria-label={`Go to client testimonial ${i + 1}`}
            />
          ))}
        </div>
        <Link
          href="/client-testimonials"
          className={classNames('button', 'button--small', styles.moreButton)}
        >
          More Testimonials
        </Link>
      </div>
    </section>
  )
}

const Testimonial = ({ highlight, excerpt, isCapped, name }) => {
  return (
    <div className={styles.testimonial}>
      <div className={styles.testimonial__highlight}>{highlight}</div>
      <blockquote className={styles.testimonial__body}>
        <PortableText value={excerpt} />
      </blockquote>
      {isCapped && (
        <Link
          className={styles.testimonial__readMore}
          href="/client-testimonials"
        >
          Read more…
        </Link>
      )}
      <figcaption className={styles.testimonial__name}>{name}</figcaption>
    </div>
  )
}

export const wrap = (min, max, v) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}
