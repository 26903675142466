import Head from 'next/head'

import HeroImage from '@bww/beke-ui/HeroImage'
import Intro from '@/components/home/intro/home-intro'
import Brands from '@bww/beke-ui/BrandsSection'
import Inspiration from '@bww/beke-ui/InspirationSection'
import YourDreamCTA from '@bww/beke-ui/YourDreamCTA'
import Testimonials from '@bww/beke-ui/TestimonialsSlider'

import { sanityClient } from '@bww/beke-lib/sanity-client'
import { createExcept } from '@bww/beke-lib/pt-excerpt'

export default function Home({ hero, brands, inspirationItems, testimonials }) {
  return (
    <>
      <Head>
        <title>
          Bathroom Elegance | Bathroom Designers & Suppliers in Poole
        </title>
        <meta
          name="description"
          content="A huge range of bathroom products in our 4000 sq. foot showroom. Everything you could possibly require for a new bathroom."
        />
      </Head>
      <HeroImage hero={hero} />
      <Intro />
      {/* <Results /> */}
      {/* <DesignsThatComplement /> */}
      <Brands brands={brands}>
        <p>
          Bathroom Elegance have close partnerships with leading bathroom
          fixture and furniture manufacturers.
        </p>
        <p>
          This provides the highest levels of quality, innovation, value and
          choice.
        </p>
      </Brands>
      <Inspiration items={inspirationItems} />
      <Testimonials testimonials={testimonials} />
      <YourDreamCTA />
    </>
  )
}

export async function getStaticProps() {
  const { home, brands, testimonials, inspirationItems } =
    await sanityClient.fetch(
      `{
      'home': *[_id == "be-home"][0]{
        hero->{
          _type == 'project' => {
            "type": _type,
            title,
            "slug": slug.current,
            "heroImg": images[0]{ ..., "lqip": asset->metadata.lqip },
          },
          _type == 'product-feature' => {
            "type": _type,
            title,
            "slug": slug.current,
            images[]{
              ...,
              "lqip": asset->metadata.lqip,
              "aspectRatio": asset->metadata.dimensions.aspectRatio
            },
            description,
            brand->{
              name,
              "slug": slug.current,
              logo{ "src": asset->url, "bleedColor": bleedColor.hex }
            }
          }
        },
      },
      'brands': *[_type == "brand" && "be" in companies]{
        name,
        logo{ "src": asset->url, "bleedColor": bleedColor.hex }
      },
      'testimonials': *[_type == "testimonial" && company == "be"] | order(date desc, _createdAt desc)[0..9],
      'inspirationItems': *[_id == "be-inspiration"].items[0...3]->{
        _type == 'project' => {
          "type": _type,
          title,
          "slug": slug.current,
          "image": images[0]{ ..., "lqip": asset->metadata.lqip },
          shortDescription
        },
        _type == "insight" => {
          "type": _type,
          title,
          "slug": slug.current,
          image{ ..., "lqip": asset->metadata.lqip },
          description
        },
        _type == 'product-feature' => {
          "type": _type,
          title,
          "slug": slug.current,
          "image": images[0]{ ..., "lqip": asset->metadata.lqip },
          description,
          brand->{
            name,
            "slug": slug.current,
            logo{ "src": asset->url }
          }
        }
      }
    }`,
    )

  const testimonialsWithExcerpt = testimonials.map((t) => {
    const excerpt = createExcept(t.testimonial)
    return { ...t, excerpt }
  })

  return {
    props: {
      hero: home.hero,
      brands,
      testimonials: testimonialsWithExcerpt,
      inspirationItems,
    },
  }
}
