import Card from '../InspirationCard'
import CreditTag from '../../CreditTag/CreditTag'

import { siteConfig } from '@bww/beke-lib/site-config'

export default function ProjectCard({ image, title, slug }) {
  return (
    <Card
      image={image}
      content={
        <>
          <img
            src={siteConfig.logoIcon.src}
            alt={`${siteConfig.companyName} logo icon`}
            height={32}
            width={32}
          />
          <CreditTag title={title} projectSlug={slug} position={['right']} />
        </>
      }
    />
  )
}
